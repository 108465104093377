<template>
    <span class="is-flex is-align-items-center is-justify-content-end" v-tooltip="'# of images captured on the checklist'">
        <icon icon="images" type="far" class="has-text-grey index-icon"/>
        <span class="ml-3">{{ imagesCount }}</span>
    </span>
</template>
<script>
export default {
    
    props: {
        answers: {
            type: Array,
            default: () => ([])
        }
    },

    computed: {
        imagesCount() {
            return this.answers.reduce((total, answer) => total + answer.images_count, 0)
        }
    }

}
</script>